import { isFacebookUser, isGoogleUser } from 'selectors/socialSelectors';
import { getIsLoggedIn, getStoredProfile } from 'selectors/userSelectors';
import { AuthenticationResponse } from 'types/api/userApi';
import { Profile, ProfilePrivateProperties } from 'types/user';
import { signOutFacebook } from './bridgeActions';
import { ThunkAction } from './helpers';
import { push } from './history';
import { updateVerifyAccount } from './interUserApiActions';
import { removeStoredAuthenticationToken } from './nativeActions';
import { googleSignOut } from './socialActions';
import { createProfile } from './talpaUserApiActions';
import {
  clearUser,
  setLoggingOut,
  setSessionValidationState,
  storeUser,
  storeUserProfile,
} from './userActions';

interface UpdateProfilePayload {
  displayName: string;
  avatarUrl: string;
  properties?: Record<string, string>;
  privateProperties?: Record<string, string>;
}

export const registerAsGuest = (
  profile: Record<string, any> = {},
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  dispatch(setSessionValidationState('VALIDATING'));

  try {
    const response = await api.post<any>('users/register/guest', {
      optionalValues: {
        privateProperties: {
          ...profile,
        },
      },
    });

    dispatch(storeUser(response));
  } catch (error) {
    console.log('err', error);
  }

  dispatch(setSessionValidationState('VALIDATED'));
};

export const loginByAuthenticationToken = (
  authenticationToken: string,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  dispatch(setSessionValidationState('VALIDATING'));

  try {
    const response = await api.post<AuthenticationResponse>(
      'users/usersessions/authenticationToken',
      {
        authenticationToken,
      },
    );

    const fromApple = /apple\.com/.test(document.referrer);

    if (fromApple && response.loginCredentialStatus.externalIds?.APPLE?.id) {
      updateVerifyAccount({
        email: response.loginCredentialStatus.email,
        getState,
        dispatch,
        response,
      });
    } else {
      dispatch(storeUser(response));
    }
  } catch (error: any) {
    switch (error.status) {
      // Logout is "user session not found"
      case 404:
        dispatch(clearUser());
        break;
      default:
        throw error;
    }
  }

  dispatch(setSessionValidationState('VALIDATED'));
};

export const continueSession = (token?: string): ThunkAction<Promise<void>> => async (dispatch) => {
  if (token) {
    await dispatch(loginByAuthenticationToken(token));
  }
};

export const logout = (isMultipleSessions?: boolean): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
) => {
  const appState = getState();

  try {
    dispatch(setLoggingOut(true));
    dispatch(removeStoredAuthenticationToken());
    if (isFacebookUser(appState)) await dispatch(signOutFacebook());

    if (isGoogleUser(appState)) {
      await dispatch(googleSignOut());
    }

    if (isMultipleSessions) {
      dispatch(clearUser());
    } else {
      await dispatch(removeAccount());
    }
  } catch (error: any) {
    throw new Error(error);
  }

  setTimeout(() => {
    dispatch(push('/'));
    window.location.reload();
  }, 100);
};

export const updateProfile = (
  profile: ProfilePrivateProperties,
  isLoggedInOverride = false,
): ThunkAction<Promise<Profile | undefined>> => async (dispatch, getState, { api }) => {
  try {
    const isLoggedIn = isLoggedInOverride || getIsLoggedIn(getState());

    let updatedProfile: Profile = {
      privateProperties: profile,
    };

    if (!isLoggedIn) {
      dispatch(storeUserProfile(updatedProfile));
    } else {
      const storedProfile = getStoredProfile(getState());
      const displayName = [
        profile.firstName || storedProfile.firstName,
        profile.lastName || storedProfile.lastName,
      ]
        .filter((str) => !!str)
        .join(' ');
      const nonEmptyProfile = Object.entries(profile).reduce((reduced, [key, value]) => {
        if (!!value) {
          reduced[key as keyof ProfilePrivateProperties] = value;
        }

        return reduced;
      }, {} as Partial<ProfilePrivateProperties>);

      const response = await api.put<{ profile: Profile }, UpdateProfilePayload>('users/profile', {
        displayName,
        avatarUrl: profile.avatarUrl || '',
        privateProperties: {
          ...nonEmptyProfile,
        },
      });

      updatedProfile = response?.profile;

      if (updatedProfile.privateProperties) {
        dispatch(createProfile(updatedProfile.privateProperties));
      }
    }

    dispatch(storeUserProfile(updatedProfile));
    return updatedProfile;
  } catch (error) {
    console.log('err', error);
  }

  return undefined;
};

export const removeAccount = (): ThunkAction<Promise<void>> => async (
  dispatch,
  _getState,
  { api },
) => {
  try {
    await api.delete('users/usersessions/current');
  } catch (error) {
    throw error;
  }

  dispatch(clearUser());
};

interface AddExternalIdCredentialsPayload {
  type: 'GOOGLE' | 'APPLE';
  externalId: string;
  displayName?: string;
}

export const addExternalIdCredentials = (
  payload: AddExternalIdCredentialsPayload,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  try {
    const response = await api.post<any>(`users/credentials/type/${payload.type}`, {
      externalId: payload.externalId,
      displayName: payload.displayName,
    });

    updateVerifyAccount({ getState, dispatch, response });
  } catch (error: any) {
    throw error;
  }
};
