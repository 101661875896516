import { AppState } from 'reducers';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getRegistrationProfile } from 'selectors/userSelectors';
import { AuthenticationResponse } from 'types/api/userApi';
import { ThunkAction, ThunkExtraArgument } from './helpers';
import { waitForApple } from './socialActions';
import {
  setSessionValidationState,
  storeUser,
  updateEmailFieldStorage,
  updateRegistrationProfile,
} from './userActions';
import { logout, updateProfile } from './usersApiActions';

export interface LoginByEmailData {
  email: string;
  password: string;
}

export interface LoginByEmailPayload {
  email: string;
  password: string;
  displayName: string;
  avatarUrl: string;
  optionalValues?: Record<string, any>;
}

export interface RegisterWithEmailData {
  ageGroup: AgeGroups;
  email: string;
  firstName: string;
  location: RegionNamesInGerman;
  gender: Genders;
  terms: boolean;
  participation: boolean;
  privacy: boolean;
  password: string;
}

export interface RegisterWithEmailPayload {
  email: string;
  password: string;
  displayName: string;
  avatarUrl: string;
}

export interface VerifyEmailPayload {
  code: string;
}

export interface CheckLoginProps {
  getState: () => AppState;
  dispatch: ThunkDispatch<AppState, ThunkExtraArgument, AnyAction>;
  response: AuthenticationResponse;
}

export const checkLogin = ({ getState, dispatch, response }: CheckLoginProps) => {
  const appState = getState();
  const registrationProfile = getRegistrationProfile(appState);

  dispatch(storeUser(response));

  if (registrationProfile) {
    dispatch(updateProfile(registrationProfile, true));
  }
};

export type UpdateVerifyAccountProps = { email?: string } & CheckLoginProps;

export const updateVerifyAccount = ({
  email,
  getState,
  dispatch,
  response,
}: UpdateVerifyAccountProps) => {
  dispatch(updateProfile({ email, redBullMigrated: 'false' }));
  checkLogin({ getState, dispatch, response });
};

export const addEmailCredentials = (
  formData: LoginByEmailData,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  try {
    const response = await api.post<any, LoginByEmailPayload>('inter/user/credentials/email', {
      email: formData.email,
      password: formData.password,
      displayName: '',
      avatarUrl: '',
    });

    updateVerifyAccount({ email: formData.email, getState, dispatch, response });
  } catch (error) {
    throw error;
  }
};

export const loginByEmail = (formData: LoginByEmailData): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { api },
) => {
  try {
    dispatch(updateEmailFieldStorage(formData.email));

    const response = await api.post<AuthenticationResponse, LoginByEmailPayload>(
      'inter/user/usersessions/email',
      {
        email: formData.email,
        password: formData.password,
        displayName: '',
        avatarUrl: '',
      },
    );

    checkLogin({ getState, dispatch, response });
  } catch (error) {
    throw error;
  }
};

export const registerWithEmail = (
  formData: RegisterWithEmailData,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  try {
    dispatch(updateEmailFieldStorage(formData.email));

    await dispatch(updateRegistrationProfile(formData));

    await api.post<any, RegisterWithEmailPayload>('inter/user/register/email', {
      email: formData.email,
      password: formData.password,
      displayName: '',
      avatarUrl: '',
    });
  } catch (error) {
    throw error;
  }
};

export const verifyEmail = ({ code }: VerifyEmailPayload): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { api },
) => {
  try {
    await api.post<any, VerifyEmailPayload>('inter/user/verify/email', { code });
  } catch (error) {
    throw error;
  }
};

export const confirmAuthentication = (): ThunkAction<Promise<boolean>> => async (
  dispatch,
  getState,
  { api },
) => {
  try {
    const response = await api.post<AuthenticationResponse>(
      'inter/user/usersessions/confirm',
      undefined,
      true,
    );

    if (response?.loginCredentialStatus?.email) {
      await checkLogin({ getState, dispatch, response });

      dispatch(waitForApple(true));
      dispatch(setSessionValidationState('VALIDATED'));
      return true;
    }
  } catch {}

  return false;
};

interface LoginOrRegisterByGooglePayload {
  googleId: string;
  email: string;
  displayName: string;
}

export const loginOrRegisterByGoogle = (
  payload: LoginOrRegisterByGooglePayload,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  try {
    if (payload.displayName) {
      const namePieces = payload.displayName.split(' ');
      const firstName = namePieces.shift();
      const lastName = namePieces.join(' ');

      dispatch(storeUser({ profile: { firstName, lastName } }));
    }

    const response = await api.post<AuthenticationResponse, LoginOrRegisterByGooglePayload>(
      'inter/google/user',
      payload,
    );

    checkLogin({ getState, dispatch, response });
  } catch (error) {
    throw error;
  }
};

interface RequestPasswordChangePayload {
  email: string;
}

export const requestPasswordChange = (
  payload: RequestPasswordChangePayload,
): ThunkAction<Promise<void>> => async (dispatch, getState, { api }) => {
  try {
    await api.post<any, RequestPasswordChangePayload>('inter/user/password/requestChange', payload);
  } catch (error) {
    throw error;
  }
};

export const requestDeletion = (): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { api },
) => {
  try {
    api.post('inter/user');

    window.setTimeout(() => {
      dispatch(logout(true));
    }, 200);
  } catch (error) {
    throw error;
  }
};
