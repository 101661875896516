import { fetchEpisode } from 'actions/episodeActions';
import { AppState } from 'reducers';
import { Store } from 'redux';
import { getEventsByEpisodeCode } from 'selectors/eventsSelectors';
import { isAuthenticated } from 'selectors/userSelectors';
import { Episode } from 'types/episodes';

let timer: number;
let started = false;
let prevEpisode: Episode | null = null;

export const connectEpisode = (store: Store<AppState, any>) => {
  const getEpisode = async () => {
    clearTimeout(timer);

    if (!started) {
      return;
    }

    try {
      await store.dispatch(fetchEpisode());
    } catch (error) {
      if (error.status === 404) {
        timer = window.setTimeout(getEpisode, 5000, store);
      } else {
        throw error;
      }
    }
  };

  /**
   * Fetch episode "events" when episode started without events stored in redux
   */
  const fetchEpisodeWhenStarted = async () => {
    const state = store.getState();

    if (!state.episode) {
      window.setTimeout(fetchEpisodeWhenStarted, 10000);
      return;
    }

    const hasStarted = state.episode.state === 'BROADCAST';
    const events = getEventsByEpisodeCode(state, state.episode.episodeCode);
    const hasEvents = !!events.length;

    if (hasStarted && !hasEvents) {
      await store.dispatch(fetchEpisode());
    }

    window.setTimeout(fetchEpisodeWhenStarted, 500);
  };

  fetchEpisodeWhenStarted();

  store.subscribe(() => {
    const state = store.getState();
    const authenticated = isAuthenticated(state);
    const { suspended } = state.native;

    // App suspended
    if (suspended) {
      return;
    }

    if (!started && authenticated && !state.episode) {
      started = true;
      getEpisode();
    }

    if (started && !authenticated) {
      started = false;
    }

    // Automatically start polling when episode is reset.
    if (authenticated && prevEpisode && !state.episode) {
      getEpisode();
    }

    prevEpisode = state.episode;
  });
};
