import { Form } from '@blockle/form';
import { registerWithEmail, RegisterWithEmailData } from 'actions/interUserApiActions';
import { logout, updateProfile } from 'actions/usersApiActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Divider from 'components/Divider';
import FlatButton from 'components/FlatButton';
import { AgeGroupDropdown, RegionDropdown } from 'components/FormElements';
import RadioButton from 'components/RadioButton';
import { PolicyFields } from 'components/Registration';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmailUser } from 'selectors/socialSelectors';
import {
  getEmailFieldStorage,
  getIsLoggedIn,
  getProfileIncomplete,
  getRegistrationFields,
  getStoredProfile,
} from 'selectors/userSelectors';
import { getErrorCode } from 'utils/error';

interface Props {
  next: () => void;
}

type Dialog = {
  dialogTitle: string;
  dialogContent: string;
};

const Step1 = ({ next }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<Dialog | false>(false);

  const profile = useSelector(getStoredProfile);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { isRequiredMissing } = getProfileIncomplete(profile);
  const emailUser = useSelector(isEmailUser);
  const emailFieldStorage = useSelector(getEmailFieldStorage);

  const onSubmit = async (formData: RegisterWithEmailData) => {
    try {
      if (profile.email) {
        await dispatch(updateProfile(getRegistrationFields({ ...formData, email: profile.email })));
      } else if (emailUser) {
        await dispatch(registerWithEmail(formData));
      }
    } catch (error: any) {
      const code = getErrorCode(error);

      switch (code) {
        case 'EMAIL_NOT_UNIQUE': {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Email is already in use.'),
          });
        }
        case 'EMAIL_NOT_VERIFIED': {
          return next();
        }
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }

    next();
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ submitting }) => (
          <>
            <Box padding="gutter">
              <Stack spacing="large">
                <Stack spacing="small">
                  <Text
                    component="h1"
                    fontSize="xlarge"
                    fontWeight="bold"
                    color="secondary"
                    textAlign="center"
                  >
                    {t('Create a profile!')}
                  </Text>
                  <Text fontSize="small" textAlign="center">
                    {t('and you have the opportunity to qualify as a candidate for the studio!')}
                  </Text>
                </Stack>
                <Stack spacing="medium">
                  {(!isLoggedIn || (emailUser && !profile.email)) && (
                    <TextField
                      name="email"
                      type="email"
                      label={t('E-Mail')}
                      placeholder={t('quizmaster@servustv.com')}
                      required
                      errorMessages={{
                        required: t('REQUIRED'),
                      }}
                      value={emailFieldStorage}
                    />
                  )}
                  {(!isLoggedIn || (emailUser && !profile.email)) && (
                    <TextField
                      name="password"
                      type="password"
                      label={t('Password')}
                      placeholder={t('Minimum of 6 characters')}
                      required
                      minLength={6}
                      errorMessages={{
                        required: t('REQUIRED'),
                        minLength: t('Minimum of 6 characters'),
                      }}
                    />
                  )}
                  <TextField
                    required
                    name="firstName"
                    label={t('First Name')}
                    placeholder={t('Your name here')}
                    errorMessages={{
                      required: t('REQUIRED'),
                    }}
                    value={profile.firstName}
                  />
                  <AgeGroupDropdown value={profile.ageGroup} />
                  <RegionDropdown value={profile.location} />
                  <Stack spacing="large" horizontal role="radiogroup">
                    <RadioButton
                      name="gender"
                      value="MALE"
                      label={t('Male')}
                      required
                      checked={profile.gender === 'MALE'}
                    />
                    <RadioButton
                      name="gender"
                      value="FEMALE"
                      label={t('Female')}
                      required
                      checked={profile.gender === 'FEMALE'}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
            <Divider />
            <PolicyFields
              submitting={submitting}
              checked={{
                terms: profile.terms === 'true',
                participation: profile.participation === 'true',
                privacy: profile.privacy === 'true',
              }}
            />
            {isLoggedIn && isRequiredMissing && (
              <Stack align="center" spacing="large">
                <FlatButton onClick={() => dispatch(logout())}>
                  {t('Cancel Registration')}
                </FlatButton>
              </Stack>
            )}
          </>
        )}
      />
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="large">
              <Stack align="center" spacing="medium">
                <Text color="secondary" fontSize="large" fontWeight="medium">
                  {dialog && dialog.dialogTitle}
                </Text>
                <Text color="black" fontSize="small">
                  {dialog && dialog.dialogContent}
                </Text>
              </Stack>
              <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default Step1;
