import { EpisodeReducer } from 'reducers/episode';
import { EpisodeFriendScoresReducer } from 'reducers/episodeFriendScores';
import { getFacebookFriendIds } from 'selectors/socialSelectors';
import {
  QzmCurrentEpisode,
  QzmCurrentEpisodeResponse,
  QzmFriendScores,
  QzmScoreUsers,
} from 'types/api/episodeApi';
import { Block, Episode } from 'types/episodes';
import { decrypt } from 'utils/decrypt';
import { setEvents } from './eventActions';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';

// Thunk actions.
export const fetchEpisode = (): ThunkAction<Promise<QzmCurrentEpisode>> => async (
  dispatch,
  _getState,
  { api },
) => {
  try {
    const response = await api.get<QzmCurrentEpisodeResponse>('qzm/episodes/current');

    // This check is done so Jest can mock the response without us trying to decrypt it here.
    const currentEpisode =
      response.token && response.data
        ? JSON.parse(decrypt(response.data, response.token))
        : response;

    const { events = [], ...episode } = currentEpisode as QzmCurrentEpisode;
    const episodeEvents = events.map((event) => ({ ...event, episodeCode: episode.episodeCode }));

    dispatch(setEvents(episodeEvents));
    dispatch(setEpisode(episode));
    return episode;
  } catch (error) {
    throw error;
  }
};

export const getFriendScores = (episodeCode: string): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { api },
) => {
  const state = getState();
  const friendIds = getFacebookFriendIds(state);

  if (!friendIds || friendIds.length === 0) return;

  try {
    const response = await api.get<QzmFriendScores>(
      `qzm/userstate/${episodeCode}/score?gigyaIds=${friendIds.join(',')}`,
    );

    const friends = response.list.map((friend) => ({ ...friend, episodeCode }));

    dispatch(setFriendScores(friends));
  } catch (error) {
    throw error;
  }
};

export const updateFriendScores = (episodeCode: string): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { api },
) => {
  const state = getState();
  const friendIds = getFacebookFriendIds(state);

  if (!friendIds || friendIds.length === 0) return;

  try {
    dispatch(setFriendScores([]));

    const response = await api.get<QzmScoreUsers>(
      `qzm/leaderboard/${episodeCode}/users?gigyaUids=${friendIds.join(',')}`,
    );

    const friends = response.entries.map((friend) => ({ ...friend, episodeCode }));

    dispatch(setFriendScores(friends));
  } catch (error) {
    throw error;
  }
};

// Standard actions.
export const setEpisode = (episode: EpisodeReducer) =>
  createActionWithPayload('@episode/SET_EPISODE', episode);

export const updateEpisode = (episode: Partial<Episode>) =>
  createActionWithPayload('@episode/UPDATE_EPISODE', episode);

export const updateBlock = (block: Block) =>
  createActionWithPayload('@episode/UPDATE_BLOCK', block);

export const clearEpisode = () => createAction('@episode/CLEAR_EPISODE');

export const setFriendScores = (friends: EpisodeFriendScoresReducer) =>
  createActionWithPayload('@episode/SET_FRIEND_SCORES', friends);

export type EpisodeActions = ReturnType<
  | typeof setEpisode
  | typeof updateEpisode
  | typeof clearEpisode
  | typeof setFriendScores
  | typeof updateBlock
>;
