import { push } from 'actions/history';
import { waitForApple } from 'actions/socialActions';
import { createProfile } from 'actions/talpaUserApiActions';
import { setLoggedIn } from 'actions/userActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHasExternalIds, getProfileIncomplete, getStoredProfile } from 'selectors/userSelectors';

export const useLoginFlow = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getStoredProfile);
  const hasExternalIds = useSelector(getHasExternalIds);

  useEffect(() => {
    if (!profile.email && !hasExternalIds) {
      return;
    }

    window.setTimeout(() => {
      const { isRequiredMissing, isIncomplete } = getProfileIncomplete(profile);

      if (profile && !isRequiredMissing) {
        dispatch(createProfile(profile));
      }

      dispatch(push(isIncomplete ? '/sign-up' : '/'));
      dispatch(setLoggedIn(true));
      dispatch(waitForApple(false));
    }, 200);
  }, [profile.email, hasExternalIds]);
};
