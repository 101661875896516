import { graphFacebook, openBrowser } from 'actions/bridgeActions';
import { getFriendScores, updateFriendScores } from 'actions/episodeActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading/Heading';
import Icon from 'components/Icon';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Points from 'components/Points';
import Stack from 'components/Stack';
import TopTierPlayer from 'components/Statistics/TopTierPlayer';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import InterstitialImage from 'public/images/interstitial.jpg';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getFriendScoresByEpisodeCode, isLeaderboardDone } from 'selectors/episodeSelectors';
import { getFacebookFriendIds, isFacebookUser } from 'selectors/socialSelectors';
import { PhaseNames, WaitingForLeaderboardEvent } from 'types/events';
import WaitingForLeaderboardFinished from './WaitingForLeaderboardFinished';

interface Props {
  episodeCode: string;
  event: WaitingForLeaderboardEvent;
  eventPhase: PhaseNames;
}

const WaitingForLeaderboard: FC<Props> = ({ episodeCode, event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isTopFriendsButtonPressed, setTopFriendsButtonPressed] = useState(false);
  const leaderboardReady = useSelector(isLeaderboardDone);
  const [showRanking, setShowRanking] = useState(false);
  const userHasFacebook = useSelector(isFacebookUser);
  const friendScores = useSelector((state: AppState) =>
    getFriendScoresByEpisodeCode(state, episodeCode),
  );
  const friendIds = useSelector(getFacebookFriendIds);
  const hasFriendScores = userHasFacebook && friendScores.length > 0;

  useEffect(() => {
    if (userHasFacebook) {
      dispatch(graphFacebook('friends, permissions'));
      dispatch(getFriendScores(episodeCode));
    }
  }, [userHasFacebook, friendIds.join(',')]);

  const revealTopFriends = () => {
    setTopFriendsButtonPressed(true);

    if (!hasFriendScores) return;

    dispatch(updateFriendScores(episodeCode));
  };

  if (showRanking) {
    return <WaitingForLeaderboardFinished episodeCode={episodeCode} />;
  }

  return (
    <>
      <TopBar left={<MenuToggle />} right={<Points episodeCode={episodeCode} />} />

      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Stack spacing="large" style={{ zIndex: -1 }}>
          <Heading title={t('Stay tuned for your ranking')} subTitle={event.content.title} />

          {!userHasFacebook && (
            <img
              style={{
                width: '100%',
                maxWidth: '100%',
              }}
              src={event.content.imageUrl || InterstitialImage}
            />
          )}
        </Stack>
      </Box>

      <Panel flexGrow={0}>
        <Stack spacing="large">
          {hasFriendScores && isTopFriendsButtonPressed && (
            <Stack spacing="small">
              <Text component="h1" color="secondary" fontSize="xlarge" textAlign="center">
                {t('Top Friends')}
              </Text>
              <Box display="flex" justifyContent="center">
                <Stack spacing="large" horizontal align="center">
                  {friendScores.map((friend) => (
                    <TopTierPlayer
                      key={friend.userId}
                      avatarUrl={friend.avatarUrl}
                      displayName={friend.displayName}
                      score={friend.score}
                    />
                  ))}
                </Stack>
              </Box>
            </Stack>
          )}

          <Stack spacing="small" align="center">
            {hasFriendScores && !isTopFriendsButtonPressed && (
              <Button
                onClick={() => revealTopFriends()}
                variant="white"
                icon={<Icon name="facebook" color="primary" />}
              >
                {t('View Top Friends')}
              </Button>
            )}
            <Button
              onClick={() => dispatch(openBrowser('http://www.servustv.com/quizmaster'))}
              variant="white"
              icon={<Icon name="open-in-new" color="primary" />}
            >
              {t('ServusTV')}
            </Button>
            <Button
              onClick={() => setShowRanking(true)}
              disabled={!leaderboardReady}
              variant="white"
              icon={<Icon name="arrow-forward" color="primary" />}
            >
              {t('View my ranking')}
            </Button>
          </Stack>
        </Stack>
      </Panel>
    </>
  );
};

export default WaitingForLeaderboard;
