import { loginByEmail } from 'actions/interUserApiActions';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import { SignInForm } from 'components/SignInForm';
import { SignInDialogProps } from 'components/SignInForm/SignInForm';
import TopBar from 'components/TopBar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SignUpNotVerified from 'screens/SignUpNotVerified';
import { getEmailFieldStorage } from 'selectors/userSelectors';
import { getErrorCode } from 'utils/error';

const SignInScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailFieldStorage = useSelector(getEmailFieldStorage);
  const [showNotVerified, setNotVerified] = useState(false);
  const [dialog, setDialog] = useState<SignInDialogProps | false>(false);

  const onSubmit = async (formData: any) => {
    try {
      await dispatch(loginByEmail(formData));
    } catch (error: any) {
      const code = getErrorCode(error);

      switch (code) {
        case 'EMAIL_INVALID':
        case 'AUTHENTICATION_INCORRECT': {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Username or Password is wrong'),
          });
        }
        case 'EMAIL_NOT_VERIFIED': {
          setNotVerified(true);
          return;
        }
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }
  };

  if (showNotVerified) {
    return <SignUpNotVerified setNotVerified={setNotVerified} />;
  }

  return (
    <>
      <TopBar
        title={t('Create a profile')}
        left={<MenuToggle toggleType="back" pushLocation="/" />}
      />
      <Panel>
        <SignInForm
          email={emailFieldStorage || ''}
          onSubmit={onSubmit}
          dialog={dialog}
          setDialog={setDialog}
        />
      </Panel>
    </>
  );
};

export default SignInScreen;
