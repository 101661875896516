import { openBrowser } from 'actions/bridgeActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import FormWarning from 'components/FormWarning/FormWarning';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  submitting: boolean;
  checked?: Record<string, boolean>;
}

const PolicyFields: FC<Props> = ({ submitting, checked }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openInBrowser = (event: React.MouseEvent) => {
    event.preventDefault();

    const { target } = event;
    const link = (target as HTMLElement).closest('a');

    if (!link) {
      return;
    }

    dispatch(openBrowser(link.href));
  };

  return (
    <Box padding="gutter">
      <Stack spacing="medium">
        <Stack spacing="small" horizontal align="center">
          <Checkbox name="terms" required checked={checked?.terms} />
          <Text color="black" fontSize="small">
            <Trans>
              I agree to the{' '}
              <a
                onClick={openInBrowser}
                href="https://richtlinien.servus.com/r/Servus_TV_Quizmaster_App/terms/de"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
            </Trans>
          </Text>
        </Stack>

        <Stack spacing="small" horizontal align="center">
          <Checkbox name="participation" required checked={checked?.participation} />
          <Text color="black" fontSize="small">
            <Trans>
              I agree to the{' '}
              <a
                onClick={openInBrowser}
                href="https://richtlinien.servus.com/policies/Servus_TV_Quizmaster_App/202104160954/de/participation.html"
                target="_blank"
                rel="noreferrer"
              >
                Conditions of Participation
              </a>
            </Trans>
          </Text>
        </Stack>

        <Stack spacing="small" horizontal align="center">
          <Checkbox name="privacy" required checked={checked?.privacy} />
          <Text color="black" fontSize="small">
            <Trans>
              I agree to the{' '}
              <a
                onClick={openInBrowser}
                href="https://richtlinien.servus.com/policies/Servus_TV_Quizmaster_App/202210031145/de/privacy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
            </Trans>
          </Text>
        </Stack>

        {/* TODO How to exclude empty elements from stack? */}
        <FormWarning inputName="terms">
          {t('You need to agree to the terms and conditions')}
        </FormWarning>
        <FormWarning inputName="participation">
          {t('You need to agree to the conditions of participation')}
        </FormWarning>
        <FormWarning inputName="privacy">{t('You need to agree to the privacy')}</FormWarning>

        <Text fontSize="xsmall" color="darkGray">
          <Trans>
            Lorum{' '}
            <a
              onClick={openInBrowser}
              href="https://richtlinien.servus.com/policies/Servus_TV_Quizmaster_App/202210031145/de/affiliates.html"
              target="_blank"
              rel="noreferrer"
            >
              verbundenen Unternehmen
            </a>{' '}
            wie in unseren{' '}
            <a
              onClick={openInBrowser}
              href="https://richtlinien.servus.com/policies/Servus_TV_Quizmaster_App/202210031145/de/privacy.html"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutzrichtlinien
            </a>{' '}
            beschrieben (z.B. Anpassung des Inhalts entsprechend Ihren Vorstellungen) verwendet
            werden dürfen.
          </Trans>
        </Text>

        <Box display="flex" justifyContent="center" paddingTop="medium">
          <Button type="submit" disabled={submitting}>
            {t('Next step')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default PolicyFields;
