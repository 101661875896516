import { ThunkAction } from './helpers';

export const trackPageView = (page: string): ThunkAction => () => {
  if ('ga' in global) {
    ga('send', 'pageview', { page });
  }
};

export const trackEvent = (eventCategory: string, eventAction: string): ThunkAction => () => {
  if ('ga' in global) {
    ga('send', 'event', { eventCategory, eventAction });
  }
};
