import { AppState } from 'reducers';
import { PracticeQuestionEvent } from 'types/events';
import { getClientTime } from 'utils/clientTime';
import {
  getCategoryPointRundown,
  getEvent,
  getEventsByEpisodeCode,
  getInteractionsByCategoryId,
  getRoundsByEpisodeCode,
} from './eventsSelectors';
import { getPracticeSchedule } from './practiceScheduleSelectors';

export const getPracticeAnswer = (state: AppState, eventId: string) =>
  state.practiceAnswers[eventId];

export const getPracticePoints = (state: AppState) => {
  const practiceAnswers = Object.values(state.practiceAnswers);
  const { currentEventId, currentEventPhase } = state.practiceSchedule;

  return practiceAnswers
    .filter(({ eventId, answerCode }) => {
      const event = getEvent(state, eventId);

      if (!event || event.type !== 'PRACTICEQUESTION') {
        return false;
      }

      if (
        eventId === currentEventId &&
        (currentEventPhase?.name === 'STOP' || currentEventPhase?.name === 'START')
      ) {
        return false;
      }

      if (event.content.correctAnswer === answerCode) {
        return true;
      }

      return false;
    })
    .reduce((totalPoints, answer) => {
      const event = getEvent(state, answer.eventId) as PracticeQuestionEvent;
      if (!event || !event.content) return totalPoints;
      const pointRundown = getCategoryPointRundown(state, event.content.categoryId, true);
      return totalPoints + pointRundown.answerScore;
    }, 0);
};

export const getCategoryPointsByEventId = (state: AppState, eventId: string) => {
  const event = getEvent(state, eventId) as PracticeQuestionEvent;

  if (!event || !event.content.categoryId) {
    return 0;
  }

  const practiceAnswers = Object.values(state.practiceAnswers);
  const events = getEventsByEpisodeCode(state, event.episodeCode);
  const categoryEvents = events.filter(
    ({ content: { categoryId } }) => categoryId === event.content.categoryId,
  );

  const correctAnswers = practiceAnswers.filter(({ eventId, answerCode }) => {
    if (!categoryEvents.find((event) => event.eventId === eventId)) {
      return false;
    }

    const event = getEvent(state, eventId);

    if (!event || event.type !== 'PRACTICEQUESTION') {
      return false;
    }

    if (event.content.correctAnswer === answerCode) {
      return true;
    }

    return false;
  });

  const pointRundown = getCategoryPointRundown(state, event.content.categoryId, true);

  return correctAnswers.length * pointRundown.answerScore;
};

export const getPracticeCategoryOverview = (state: AppState) => {
  const schedule = getPracticeSchedule(state);
  const rounds = getRoundsByEpisodeCode(state, 'practice').filter(
    ({ content }) => !!content.categoryId,
  );

  const scheduleOffset = getClientTime() - schedule.startTime;

  return rounds.map(({ content, offset }, i) => {
    const nextRound = rounds[i + 1];
    let categoryFinished = false;
    let categoryRunning = offset < scheduleOffset;

    if (nextRound) {
      categoryRunning = scheduleOffset > offset && scheduleOffset < nextRound.offset;
      categoryFinished = scheduleOffset >= nextRound.offset;
    }

    const questions = getInteractionsByCategoryId(state, 'practice', content.categoryId);
    const correctQuestions = questions.filter(({ eventId, type, content }) => {
      const answer = getPracticeAnswer(state, eventId);

      if (!answer || type !== 'PRACTICEQUESTION') {
        return false;
      }

      if (answer.answerCode === content.correctAnswer) {
        return true;
      }

      return false;
    });

    const pointRundown = getCategoryPointRundown(state, content.categoryId, true);

    return {
      categoryId: content.categoryId,
      points: correctQuestions.length * pointRundown.answerScore,
      totalPoints: questions.length * pointRundown.answerScore,
      title: content.text,
      imageUrl: content.imageUrl,
      categoryFinished,
      categoryRunning,
    };
  });
};
