import { Form } from '@blockle/form';
import { push } from 'actions/history';
import { setRegistrationDone } from 'actions/registrationActions';
import { updateProfile } from 'actions/usersApiActions';
import AvatarSelect from 'components/AvatarSelect';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getStoredProfile } from 'selectors/userSelectors';

interface FormData {
  lastName: string;
  phone?: string;
  avatarUrl?: string;
}

type Dialog = {
  dialogTitle: string;
  dialogContent: string;
};

const Step2: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<Dialog | false>(false);
  const { avatarUrl, lastName, phone } = useSelector(getStoredProfile);

  const onSubmit = async (formData: FormData) => {
    try {
      await dispatch(
        updateProfile({
          lastName: formData.lastName,
          phone: formData.phone,
        }),
      );
    } catch {
      return setDialog({
        dialogTitle: t('Error'),
        dialogContent: t('Something went wrong'),
      });
    }

    dispatch(push('/'));
  };

  const skip = () => {
    dispatch(setRegistrationDone());
    dispatch(push('/'));
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ submitting }) => (
          <Stack spacing="medium" padding="gutter">
            <Stack spacing="large">
              <Stack spacing="small">
                <Text
                  component="h1"
                  fontSize="xlarge"
                  fontWeight="bold"
                  color="secondary"
                  textAlign="center"
                >
                  {t('Almost done!')}
                </Text>
                <Text fontSize="small" textAlign="center">
                  {t('and you have the opportunity to qualify as a candidate for the studio!')}
                </Text>
              </Stack>
            </Stack>
            <Stack spacing="medium" paddingX="gutter">
              <Stack spacing="medium">
                <Box display="flex" justifyContent="center">
                  <AvatarSelect name="avatarUrl" avatarUrl={avatarUrl} />
                </Box>
                <TextField
                  name="phone"
                  type="tel"
                  label={t('Phone Number')}
                  placeholder={t('ie. +43123456789')}
                  value={phone}
                />
                <TextField
                  name="lastName"
                  label={t('Last Name')}
                  placeholder={t('Enter your last name')}
                  required
                  errorMessages={{
                    required: t('REQUIRED'),
                  }}
                  value={lastName}
                />
              </Stack>
              <Stack spacing="small" align="center" paddingTop="medium">
                <Button type="submit" disabled={submitting}>
                  {t('Create profile')}
                </Button>
                <FlatButton onClick={skip}>{t('Skip')}</FlatButton>
              </Stack>
            </Stack>
          </Stack>
        )}
      />
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="large">
              <Stack align="center" spacing="medium">
                <Text color="secondary" fontSize="large" fontWeight="medium">
                  {dialog && dialog.dialogTitle}
                </Text>
                <Text color="black" fontSize="small">
                  {dialog && dialog.dialogContent}
                </Text>
              </Stack>
              <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
            </Stack>
          </Box>
        )}
      />
    </>
  );
};

export default Step2;
