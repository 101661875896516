import { FieldProps, useField } from '@blockle/form';
import Box from 'components/Box';
import Stack from 'components/Stack';
import { cx } from 'cx';
import React from 'react';
import './TextField.css';

type Props = FieldProps<string> & {
  label: string;
  placeholder: string;
  onChange?: (value: string) => void;
  type?: 'text' | 'password' | 'email' | 'tel';
  errorMessages?: { [key in ErrorCodes]?: React.ReactNode };
  disabled?: boolean;
} & ValidationOptions;

type ErrorCodes = 'required' | 'patternMismatch' | 'minLength' | 'maxLength';

type ValidationOptions = {
  required?: boolean;
  pattern?: RegExp;
  minLength?: number;
  maxLength?: number;
};

const validate = ({ required, pattern, minLength, maxLength }: ValidationOptions) => (
  value: string,
) => {
  if (required && !value) {
    return 'required';
  }

  if (pattern && !pattern.test(value)) {
    return 'pattern';
  }

  if (minLength && value.length < minLength) {
    return 'minLength';
  }

  if (maxLength && value.length > maxLength) {
    return 'maxLength';
  }

  return null;
};

const TextField = ({
  label,
  name,
  onChange,
  pattern,
  placeholder,
  required,
  type = 'text',
  value = '',
  errorMessages = {},
  minLength,
  maxLength,
  disabled,
}: Props) => {
  const field = useField(name, {
    value,
    validate: validate({ required, pattern, minLength, maxLength }),
    onChange,
  });

  const invalid = field.invalid && (field.dirty || field.touched);

  return (
    <div className={cx('TextField', invalid && 'is-invalid')}>
      <Stack spacing="xsmall">
        <Box
          component="label"
          display="block"
          htmlFor={`Input-${name}`}
          className="TextField-label"
          fontSize="xsmall"
          fontWeight="extraBold"
          paddingX="medium"
        >
          {label}
        </Box>
        <input
          disabled={disabled}
          autoComplete="off"
          className="TextField-input"
          id={`Input-${name}`}
          name={name}
          onBlur={() => {
            field.setTouched();
          }}
          onChange={(event) => {
            field.setTouched();
            field.setValue(event.target.value);
          }}
          placeholder={placeholder}
          type={type}
          value={field.value}
        />

        {invalid && (
          <Box color="warning" paddingX="medium" fontSize="xsmall">
            {(field.validationMessage && errorMessages[field.validationMessage as ErrorCodes]) ||
              field.validationMessage}
          </Box>
        )}
      </Stack>
    </div>
  );
};

export default TextField;
