import { OverlayReducer } from 'reducers/overlay/overlayReducer';
import { QzmOverlay } from 'types/api/episodeApi';
import { createAction, createActionWithPayload, ThunkAction } from './helpers';

// Async actions
export const fetchOverlay = (): ThunkAction<Promise<void>> => async (
  dispatch,
  _getState,
  { api },
) => {
  try {
    const response = await api.get<QzmOverlay>('qzm/overlay');

    dispatch(setOverlay(response));
  } catch (error) {
    throw error;
  }
};

// Sync actions
export const setOverlay = (data: OverlayReducer) => createActionWithPayload('@overlay/SET', data);

export const closeOverlay = () => createAction('@overlay/CLOSE');

export type OverlayActions = ReturnType<typeof setOverlay | typeof closeOverlay>;
