import { AppState } from 'reducers';
import { CategoryStartEvent } from 'types/events';
import { AnswerValue } from 'types/score';
import { getClientTime } from 'utils/clientTime';
import { getSchedule } from './episodeScheduleSelectors';
import {
  getCategoryPointRundown,
  getEvent,
  getEventsByEpisodeCode,
  getInteractionsByCategoryId,
  getRoundsByEpisodeCode,
} from './eventsSelectors';
import { getPracticePoints } from './practiceAnswerSelectors';

type AnswerList = Record<string, AnswerValue>;

export const getAnswers = (state: AppState) => state.answers.resolvedAnswers;

export const getAnswersByEpisodeCode = (state: AppState, episodeCode: string): AnswerList => {
  return getAnswers(state)[episodeCode] || {};
};

export const getAnswer = (state: AppState, episodeCode: string, eventId?: string) => {
  if (!eventId) return;
  const episodeAnswers = getAnswersByEpisodeCode(state, episodeCode);
  return episodeAnswers[eventId];
};

export const getPointsByEpisodeCode = (state: AppState, episodeCode: string): number => {
  const episodeAnswers = getAnswersByEpisodeCode(state, episodeCode);
  const points = Object.keys(episodeAnswers)
    .filter((eventId) => episodeAnswers[eventId] === 'correct')
    .map((eventId) => {
      const event = getEvent(state, eventId);

      if (!event) {
        return 0;
      }

      switch (event.type) {
        case 'STUDIOPLAYERSELECTION':
        case 'TRIVIA':
          return event.content.score;
        default: {
          if (__DEV__) {
            console.log(`getPointsByEpisodeCode - add support for type "${event.type}"`);
          }

          return 0;
        }
      }
    });

  return points.reduce((prevValue, value) => prevValue + value, 0);
};

export const getCategoryPointsByEventId = (state: AppState, eventId: string): number => {
  const event = getEvent(state, eventId) as CategoryStartEvent;

  if (!event || !event.content.categoryId) {
    return 0;
  }

  const storedAnswers = getAnswersByEpisodeCode(state, event.episodeCode);
  const events = getEventsByEpisodeCode(state, event.episodeCode);
  const categoryEvents = events.filter(
    ({ content: { categoryId } }) => categoryId === event.content.categoryId,
  );

  const correctAnswers = categoryEvents.filter(({ eventId }) => {
    const answer = storedAnswers[eventId];

    return answer === 'correct';
  });

  const pointRundown = getCategoryPointRundown(state, event.content.categoryId);

  return correctAnswers.length * pointRundown.answerScore;
};

export const getCategoryOverview = (state: AppState, episodeCode: string) => {
  const storedAnswers = getAnswersByEpisodeCode(state, episodeCode);
  const schedule = getSchedule(state);
  const rounds = getRoundsByEpisodeCode(state, episodeCode).filter(
    ({ content }) => !!content.categoryId,
  );

  const scheduleOffset = getClientTime() - (schedule.syncTime - schedule.offset);

  return rounds.map(({ content, offset }, i) => {
    const nextRound = rounds[i + 1];
    let categoryFinished = false;
    let categoryRunning = offset < scheduleOffset;

    if (nextRound) {
      categoryRunning = scheduleOffset > offset && scheduleOffset < nextRound.offset;
      categoryFinished = scheduleOffset >= nextRound.offset;
    }

    const questions = getInteractionsByCategoryId(state, episodeCode, content.categoryId);
    const correctAnswers = questions.filter(({ eventId }) => {
      const answer = storedAnswers[eventId];

      return answer === 'correct';
    });

    const pointRundown = getCategoryPointRundown(state, content.categoryId);

    return {
      categoryId: content.categoryId,
      points: correctAnswers.length * pointRundown.answerScore,
      totalPoints: questions.length * pointRundown.answerScore,
      title: content.text,
      imageUrl: content.imageUrl,
      categoryFinished,
      categoryRunning,
    };
  });
};

export const getTotalPoints = (state: AppState, episodeCode: string) => {
  const isPracticeGame = episodeCode === 'practice';

  return isPracticeGame ? getPracticePoints(state) : getPointsByEpisodeCode(state, episodeCode);
};
