import { AppState } from 'reducers';
import { Profile, userProfileOptionalProperties, userProfileRequiredProperties } from 'types/user';
import { getSocialType } from './socialSelectors';

export const getEmailInvalidForAuthentication = (state: AppState) =>
  state.user.loginCredentialStatus?.emailInvalidForAuthentication;

export const getExternalIds = (state: AppState) => state.user.loginCredentialStatus?.externalIds;

export const getHasExternalIds = (state: AppState) => {
  const externalIds = getExternalIds(state) || {};

  return Object.keys(externalIds).length > 0;
};

export const getIsLoggingOut = (state: AppState) => state.user.isLoggingOut;

export const getAuthenticationToken = (state: AppState) => state.user.authenticationToken;

export const checkAuthenticationToken = (state: AppState) => !!getAuthenticationToken(state);

export const isAuthenticated = (state: AppState) =>
  checkAuthenticationToken(state) && getSessionValidationState(state) === 'VALIDATED';

export const getSessionValidationState = (state: AppState) => state.user.sessionValidationState;

export const getPttvSession = (state: AppState) => state.user.pttvSession;

export const getStoredProfile = (state: AppState) => state.user.profile || {};

export const getStoredProfileStatistics = (state: AppState) => state.user.profileStatistics;

export const getUserFirstName = (state: AppState) => {
  const profile = getStoredProfile(state);

  if (profile?.firstName) {
    return profile.firstName;
  }

  return;
};

export const getIsLoggedIn = (state: AppState) => state.user.isLoggedIn;

interface ParticipatedEpisode {
  episodeCode: string;
  amountParticipated: number;
  amountCorrect: number;
  score: number;
}

export const getParticipatedEpisode = (state: AppState, statisticsEpisodeCode: string) => {
  if (!state.user.profile) {
    return null;
  }

  const { participatedEpisodes, firstName, lastName, avatarUrl } = state.user.profile;

  if (!participatedEpisodes) {
    return null;
  }

  const { score } =
    participatedEpisodes.find(
      (participatedEpisode: ParticipatedEpisode) =>
        participatedEpisode.episodeCode === statisticsEpisodeCode,
    ) || {};

  if (!score) {
    return null;
  }

  return {
    firstName,
    lastName,
    score,
    avatarUrl,
  };
};

export const getUserAvatar = (state: AppState) => {
  return state.user.profile?.avatarUrl;
};

export const getUserCountryCode = (state: AppState) => {
  return state.user.profile?.privateProperties?.countryCode;
};

export const getProfileIncomplete = (profile?: Profile) => {
  if (!profile) {
    return {
      isRequiredMissing: true,
      isOptionalMissing: true,
      isIncomplete: true,
    };
  }

  const requiredPropertiesMissing =
    userProfileRequiredProperties.filter((name) => !profile[name]).length > 0;
  const optionalPropertiesMissing =
    userProfileOptionalProperties.filter((name) => !profile[name]).length > 0;

  return {
    isRequiredMissing: requiredPropertiesMissing,
    isOptionalMissing: optionalPropertiesMissing,
    isIncomplete: requiredPropertiesMissing || optionalPropertiesMissing,
  };
};

export const getRegistrationProfile = (state: AppState) => state.user.registrationProfile;

interface RegistrationFields {
  avatarUrl?: string;
  firstName?: string;
  lastName?: string;
  gender?: Genders;
  location?: RegionNamesInGerman;
  ageGroup?: AgeGroups;
  email?: string;
  phone?: string;
  terms?: boolean;
  privacy?: boolean;
  participation?: boolean;
  externalId?: string;
}

export const getRegistrationFields = (profile: RegistrationFields) => ({
  ageGroup: profile.ageGroup,
  email: profile.email,
  firstName: profile.firstName,
  location: profile.location,
  gender: profile.gender,
  terms: typeof profile.terms === 'boolean' ? (profile.terms ? 'true' : 'false') : profile.terms,
  participation:
    typeof profile.participation === 'boolean'
      ? profile.participation
        ? 'true'
        : 'false'
      : profile.participation,
  privacy:
    typeof profile.privacy === 'boolean' ? (profile.privacy ? 'true' : 'false') : profile.privacy,
});

export const getEmailFieldStorage = (state: AppState) => state.user.emailFieldStorage;

export const getShouldConfirmAuthenticationInformation = (state: AppState) => {
  const profile = getStoredProfile(state);

  if (profile.redBullMigrated !== 'true' || !profile.firstName) {
    return 'confirmed';
  }

  const isSocialLogin = !!profile.oldGigyaExternalId;
  const bestGuessSocialType = getSocialType(state) || 'google';

  return !isSocialLogin ? 'email' : bestGuessSocialType;
};
