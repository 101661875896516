import { replace } from 'actions/history';
import Box from 'components/Box';
import Button from 'components/Button';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import QZMImage from 'public/images/QM_Moravec_Studio_v01-2.jpg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface Props {
  onProceed: () => void;
}

// TODO Preload image
const QuizmasterScreen = ({ onProceed }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" height="full">
      <TopBar />

      <Box flexGrow={1} display="flex" alignItems="center" paddingBottom="xlarge">
        <Stack spacing="large" align="center">
          <Stack spacing="small" align="center" paddingX="xlarge">
            <Text fontSize="xlarge" color="secondary" textAlign="center">
              {t('Can you beat the Quizmaster?')}
            </Text>
            <Text color="white" fontSize="small" textAlign="center">
              {t(
                'In the training mode you have the possibility to test your knowledge at any time, during the show you can match up with the candidates in the studio',
              )}
            </Text>
          </Stack>

          <img
            style={{
              maxWidth: '100%',
            }}
            src={QZMImage}
          />

          <Button
            onClick={() => {
              onProceed();
              dispatch(replace('/'));
            }}
          >
            {t('Start')}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default QuizmasterScreen;
