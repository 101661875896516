import { Event } from 'types/events';
import { AnswerValue } from 'types/score';
import { createActionWithPayload, ThunkAction } from './helpers';

export type StorePendingAnswerPayload = {
  episodeCode: string;
  eventId: string;
  value: AnswerValue;
  eventType: Event['type'];
};

export type ResolvePendingAnswerPayload = {
  episodeCode: string;
  eventType: Event['type'];
};

export const storePendingAnswer = (payload: StorePendingAnswerPayload) =>
  createActionWithPayload('@answers/STORE_PENDING_ANSWER', payload);

export const resolvePendingAnswers = (payload: ResolvePendingAnswerPayload) =>
  createActionWithPayload('@answers/RESOLVE_PENDING_ANSWERS', payload);

type SubmitAnswer = {
  episodeCode: string;
  eventId: string;
  raw: string;
  rawPrevious?: string;
};

export const submitAnswer = ({
  episodeCode,
  eventId,
  raw,
  rawPrevious,
}: SubmitAnswer): ThunkAction => async (_dispatch, _getState, { api }) => {
  await api.put(`talpa/episodes/${episodeCode}/events/${eventId}/answer`, {
    response: raw,
    previousResponse: rawPrevious,
  });
};

export type AnswerActions = ReturnType<typeof storePendingAnswer | typeof resolvePendingAnswers>;
