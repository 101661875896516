import { openBrowser } from 'actions/bridgeActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getEpisodeHost } from 'selectors/statisticsSelectors';
import './EpisodeHost.css';

interface Props {
  episodeId: string;
}

const EpisodeHost: FC<Props> = ({ episodeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const episodeHost = useSelector((state: AppState) => getEpisodeHost(state, episodeId));

  if (!episodeHost)
    return (
      <Text fontWeight="medium" color="black" fontSize="large" textAlign="center">
        No Host available
      </Text>
    );

  const { name, imageUrl, nrOfShowsInARow, broadcastWindowStartDate } = episodeHost;

  return (
    <Box
      display="flex"
      padding="medium"
      backgroundColor="lightGrey"
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing="medium" horizontal data-testid="episode-host">
        <Avatar src={imageUrl} />
        <Stack spacing="xsmall" paddingRight="xlarge">
          <Text fontWeight="medium" color="black" fontSize="large">
            {name}
          </Text>
          <Stack spacing="xsmall" horizontal align="center">
            <Icon name="crown" size="medium" />
            <Text fontSize="small">{t('{{nrOfShowsInARow}} shows', { nrOfShowsInARow })}</Text>
          </Stack>
          <Stack spacing="small" horizontal paddingRight="medium">
            <Text color="secondary" fontSize="small">
              {t('{{date, day-date}}', {
                date: new Date(broadcastWindowStartDate),
              })}
            </Text>
            <button
              className="EpisodeHost-linkToServustv"
              onClick={() => dispatch(openBrowser('http://www.servustv.com/quizmaster'))}
            >
              <Icon name="play-circle" color="secondary" size="medium" />
            </button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EpisodeHost;
