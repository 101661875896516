import { push } from 'actions/history';
import { fetchHighlightList } from 'actions/statisticsActions';
import { getProfile } from 'actions/talpaUserApiActions';
import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Icon from 'components/Icon';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TopBar from 'components/TopBar';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getStatisticsEpisodesSorted, isLoadingStatistics } from 'selectors/statisticsSelectors';
import { getUserFirstName } from 'selectors/userSelectors';
import './StatisticsEpisodeListScreen.css';
import StatisticsNotFound from './StatisticsNotFound';

const StatisticsEpisodeListScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const statisticsEpisodes = useSelector(getStatisticsEpisodesSorted);
  const userFirstName = useSelector(getUserFirstName);
  const isLoading = useSelector(isLoadingStatistics);

  useEffect(() => {
    const fetchList = async () => {
      await dispatch(getProfile());
      dispatch(fetchHighlightList());
    };

    fetchList();
  }, []);

  if (!statisticsEpisodes) {
    return <StatisticsNotFound />;
  }

  return (
    <>
      <TopBar title={t('ShowStatistics')} left={<MenuToggle />} />

      <Panel>
        <Box paddingX="gutter">
          {isLoading ? (
            <Text textAlign="center" color="primary">
              {t('Loading')}
            </Text>
          ) : (
            <Stack spacing="small" paddingTop="small" divider>
              {statisticsEpisodes.map((statisticsEpisode) => {
                const {
                  episodeCode,
                  broadcastWindowStartDate,
                  quizMasterName,
                  quizMasterImageUrl,
                  personalScore,
                  participated,
                } = statisticsEpisode;

                return (
                  <Box
                    key={episodeCode}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    component="button"
                    onClick={() => {
                      dispatch(push(`/statistics/episode/${episodeCode}`));
                    }}
                    className="StatisticsScreen-statisticsListItem"
                  >
                    <Stack spacing="medium" horizontal align="center">
                      <Avatar src={quizMasterImageUrl} />
                      <Stack spacing="xsmall">
                        <Text
                          color="darkGray"
                          fontWeight="extraBold"
                          textTransform="uppercase"
                          fontSize="xsmall"
                        >
                          {t('{{date, day-date}}', {
                            date: new Date(broadcastWindowStartDate),
                          })}
                        </Text>
                        <Text fontWeight="medium" color="black">
                          {quizMasterName}
                        </Text>
                        <Text fontSize="xsmall" color={participated ? 'primary' : 'darkGray'}>
                          {participated
                            ? t('{{userFirstName}}: {{personalScore}} Points', {
                                userFirstName,
                                personalScore,
                              })
                            : t('no participation')}
                        </Text>
                      </Stack>
                    </Stack>
                    <Icon size="large" name="arrow-forward" color="lightGrey" />
                  </Box>
                );
              })}
            </Stack>
          )}
        </Box>
      </Panel>
    </>
  );
};

export default StatisticsEpisodeListScreen;
