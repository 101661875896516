import { push } from 'actions/history';
import Box from 'components/Box';
import { cx } from 'cx';
import { StyleProps } from 'hooks/useStyles';
import React from 'react';
import { useDispatch } from 'react-redux';
import './FlatButton.css';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
  width?: StyleProps['width'];
  variant?: 'default' | 'white';
  padding?: 'default' | 'medium';
  to?: string;
}

const FlatButton = ({
  children,
  disabled,
  onClick,
  type = 'button',
  width,
  variant = 'default',
  padding = 'default',
  to,
}: Props) => {
  const dispatch = useDispatch();
  const onAnchorClick = (event: React.MouseEvent) => {
    event.preventDefault();

    if (to) {
      dispatch(push(to));
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Box
      component={to ? 'a' : 'button'}
      className={cx('FlatButton', `variant-${variant}`, `padding-${padding}`)}
      fontSize="small"
      fontWeight="medium"
      disabled={disabled}
      onClick={to ? onAnchorClick : onClick}
      type={type}
      width={width}
      textAlign="center"
    >
      {children}
    </Box>
  );
};

export default FlatButton;
