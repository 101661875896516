import { MigrationActions } from 'actions/migrationActions';
import { Reducer } from 'redux';

export type MigrationStatus = 'PENDING' | 'IN_PROGRESS' | 'FINISHED';

export interface MigrationReducer {
  status?: MigrationStatus;
}

export const initialState: MigrationReducer = {
  status: 'PENDING',
};

export const migrationReducer: Reducer<MigrationReducer, MigrationActions> = (
  state = initialState,
  action,
): MigrationReducer => {
  switch (action.type) {
    case '@migration/SET_STATUS':
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
};
