import { QzmProfile } from 'types/api/userApi';
import { ThunkAction } from './helpers';
import { setProfileStatistics } from './userActions';

export const getProfileStatistics = (): ThunkAction<Promise<void>> => async (
  dispatch,
  _getState,
  { api },
) => {
  const profileStatistics = await api.get<QzmProfile>('qzm/profile');

  dispatch(setProfileStatistics(profileStatistics));
};
