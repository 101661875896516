import { RootActions } from 'actions';
import { ThunkExtraArgument } from 'actions/helpers';
import { api } from 'api/pttv';
import { connectAuthentication } from 'connectors/connectAuthentication';
import { bridge, connectBridge } from 'connectors/connectBridge';
import { connectEpisode } from 'connectors/connectEpisode';
import { connectEpisodeScheduler } from 'connectors/connectEpisodeScheduler';
import { connectLongpoll } from 'connectors/connectLongpoll';
import { connectPracticeScheduler } from 'connectors/connectPracticeScheduler';
import { connectPttv } from 'connectors/connectPttv';
import { connectSocial } from 'connectors/connectSocial';
import episodeScheduleMiddleware from 'middleware/episodeScheduleMiddleware';
import * as reducers from 'reducers';
import { AppState } from 'reducers';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { PersistConfig, PersistedState, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk, { ThunkMiddleware } from 'redux-thunk';

const persistConfig: PersistConfig<AppState> = {
  key: 'inter-qzm-2025',
  storage,
  whitelist: [
    'answers',
    'onboard',
    'registration',
    'practiceAnswers',
    'practiceSchedule',
    'social',
    'migration',
    'user',
  ],
};

export type AppStore = Store<AppState & PersistedState>;

const devToolEnhancer =
  process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    : (x: any) => x;

const thunkMiddleware: ThunkMiddleware<
  AppState,
  RootActions,
  ThunkExtraArgument
> = thunk.withExtraArgument({
  api,
  bridge,
});

const enhancer = compose(
  applyMiddleware(thunkMiddleware, episodeScheduleMiddleware),
  devToolEnhancer,
);

const rootReducer = combineReducers({
  ...reducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const configureStore = () => {
  const store: AppStore = createStore<AppState & PersistedState, any, any, void>(
    persistedReducer,
    enhancer,
  );
  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');

      store.replaceReducer(nextRootReducer);
    });
  }

  connectAuthentication(store);
  connectBridge(store);
  connectEpisodeScheduler(store);
  connectPracticeScheduler(store);
  connectPttv(store);
  connectEpisode(store);
  connectLongpoll(store);
  connectSocial(store);

  return {
    store,
    persistor,
  };
};
