import { FieldProps, useField } from '@blockle/form';
import { selectPhoto, takePhoto } from 'actions/bridgeActions';
import { jsUpload } from 'actions/interFileActions';
import { clear } from 'actions/photoActions';
import Avatar from 'components/Avatar/Avatar';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';
import Icon from 'components/Icon';
import Stack from 'components/Stack';
import Text from 'components/Text';
import avatarPlaceholderUrl from 'public/images/avatar-placeholder.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import './AvatarSelect.css';

interface ValidationOptions {
  required?: boolean;
}

const validate = ({ required }: ValidationOptions) => (value: string | null) => {
  if (required && !value) {
    return 'required';
  }

  return null;
};

interface DialogProps {
  dialogTitle: string;
  dialogContent: string;
}

type Props = FieldProps<string | null> & {
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  required?: boolean;
  avatarUrl?: string;
};

const AvatarSelect = ({ name, required, onChange, avatarUrl }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState<DialogProps | false>(false);
  const { imageData } = useSelector((state: AppState) => state.photo);

  const field = useField<string | null>(name, {
    value: null,
    validate: validate({ required }),
    onChange,
  });

  const onSubmit = async (imageData: any) => {
    try {
      await dispatch(jsUpload({ data: imageData, mimeType: 'image/jpg' }));
    } catch (error: any) {
      setErrorDialog({
        dialogTitle: t('Error'),
        dialogContent: t('Something went wrong'),
      });
    }
  };

  // Clear photo store on component unmount
  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  // Set form data when image is found in redux
  useEffect(() => {
    field.setValue(imageData);
    field.setTouched();
    setDialog(false);

    if (imageData) {
      onSubmit(imageData);
    }
  }, [imageData]);

  const imageSelected = imageData ? `data:image/jpg;base64,${imageData}` : null;

  // First show image selected, after that profile avatar and finally placeholder
  const imageSrc = imageSelected || avatarUrl || avatarPlaceholderUrl;

  return (
    <>
      <Box
        className="AvatarSelect"
        role="button"
        position="relative"
        onClick={() => setDialog(true)}
      >
        <Avatar src={imageSrc} size="large" />
        <button className="AvatarSelect-icon" type="button">
          <Icon name="edit" color="white" label={t('Change avatar')} size="medium" />
        </button>
      </Box>
      <Dialog
        open={dialog}
        onRequestClose={() => setDialog(false)}
        render={() => (
          <Box paddingY="gutter">
            <Stack align="center" spacing="xsmall">
              <FlatButton onClick={() => dispatch(takePhoto())}>{t('Take a picture')}</FlatButton>
              <FlatButton onClick={() => dispatch(selectPhoto())}>{t('Select photo')}</FlatButton>
              <FlatButton onClick={() => setDialog(false)}>{t('Abort')}</FlatButton>
            </Stack>
          </Box>
        )}
      />
      <Dialog
        open={Boolean(errorDialog)}
        onRequestClose={() => setErrorDialog(false)}
        render={() => {
          if (!errorDialog) return null;

          const { dialogTitle, dialogContent } = errorDialog;

          return (
            <Box paddingY="gutter">
              <Stack align="center" spacing="large">
                <Stack align="center" spacing="medium">
                  <Text color="secondary" fontSize="large" fontWeight="medium">
                    {dialogTitle}
                  </Text>
                  <Text color="black" fontSize="small">
                    {dialogContent}
                  </Text>
                </Stack>
                <Button onClick={() => setErrorDialog(false)}>{t('Confirm')}</Button>
              </Stack>
            </Box>
          );
        }}
      />
    </>
  );
};

export default AvatarSelect;
