import { Form } from '@blockle/form';
import { back } from 'actions/history';
import { requestPasswordChange } from 'actions/interUserApiActions';
import { updateEmailFieldStorage } from 'actions/userActions';
import Box from 'components/Box';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import FlatButton from 'components/FlatButton';
import MenuToggle from 'components/MenuToggle';
import Panel from 'components/Panel';
import Stack from 'components/Stack';
import Text from 'components/Text';
import TextField from 'components/TextField';
import TopBar from 'components/TopBar';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getStoredProfile } from 'selectors/userSelectors';
import { getErrorCode } from 'utils/error';

interface Props {
  showMenuToggle?: boolean;
}

interface SuccessionDialog {
  dialogTitle: string;
  dialogContent: string;
  dialogSuccessful?: boolean;
}

interface FormData {
  email: string;
}

const ForgotPasswordScreen: FC<Props> = ({ showMenuToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(getStoredProfile);

  const [dialog, setDialog] = useState<SuccessionDialog | false>(false);

  const onSubmit = async (formData: FormData) => {
    const { email } = formData;

    try {
      await dispatch(requestPasswordChange({ email }));

      dispatch(updateEmailFieldStorage(email));

      setDialog({
        dialogTitle: t('Change Password'),
        dialogContent: t('Please check your email for instructions on how to reset your password.'),
        dialogSuccessful: true,
      });
    } catch (error: any) {
      const code = getErrorCode(error);

      switch (code) {
        default: {
          return setDialog({
            dialogTitle: t('Error'),
            dialogContent: t('Something went wrong'),
          });
        }
      }
    }
  };

  function goBack() {
    dispatch(back());
  }

  return (
    <>
      <TopBar
        title={profile.email ? t('Edit profile') : t('RECOVER PASSWORD')}
        left={<MenuToggle toggleType={showMenuToggle ? 'open-menu' : 'back'} />}
      />
      <Panel>
        <Form
          onSubmit={onSubmit}
          render={({ submitting }) => (
            <Stack spacing="large" paddingX="gutter">
              <Stack spacing="small">
                <Text fontSize="xlarge" color="secondary" textAlign="center" fontWeight="bold">
                  {profile.email ? t('Change Password') : t('Forgot Password?')}
                </Text>
                {!profile.email && (
                  <Text textAlign="center" fontSize="small">
                    {t('Please enter your email address to reset the password.')}
                  </Text>
                )}
              </Stack>
              <TextField
                name="email"
                type="email"
                label={t('Email')}
                placeholder={t('Enter your email')}
                required
                errorMessages={{
                  required: t('REQUIRED'),
                }}
                value={profile.email}
                disabled={!!profile.email}
              />
              <Stack spacing="small" align="center">
                <Button type="submit" disabled={submitting}>
                  {t('Send')}
                </Button>
                <FlatButton onClick={goBack}>{t('Cancel')}</FlatButton>
              </Stack>
            </Stack>
          )}
        />
      </Panel>
      <Dialog
        open={Boolean(dialog)}
        onRequestClose={() => setDialog(false)}
        render={() => {
          if (!dialog) return null;

          const { dialogTitle, dialogContent, dialogSuccessful } = dialog;

          return (
            <Box paddingY="gutter">
              <Stack align="center" spacing="large">
                <Stack align="center" spacing="medium">
                  <Text color="secondary" fontSize="large" fontWeight="medium">
                    {dialogTitle}
                  </Text>
                  <Text color="black" fontSize="small">
                    {dialogContent}
                  </Text>
                </Stack>
                {!dialogSuccessful ? (
                  <Button onClick={() => setDialog(false)}>{t('Confirm')}</Button>
                ) : (
                  <Button onClick={() => setDialog(false)} to="/sign-in">
                    {t('Confirm')}
                  </Button>
                )}
              </Stack>
            </Box>
          );
        }}
      />
    </>
  );
};

export default ForgotPasswordScreen;
